// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCwJzhwSfbVqhvBhbVu9xz4cyKjIVL-smc',
    authDomain: 'lazy-log-3c6f5.firebaseapp.com',
    authorizedDomains: ['lazy-log-3c6f5.firebaseapp.com', 'lazy-advq023kd-protoxy.vercel.app'],
    projectId: 'lazy-log-3c6f5',
    storageBucket: 'lazy-log-3c6f5.appspot.com',
    messagingSenderId: '546618361669',
    appId: '1:546618361669:web:cefba2db3bd435223ee02c',
    measurementId: 'G-RBWMK6T3N4'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
