import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from "./services/authentication.service";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Platform} from "@ionic/angular";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  subscription: Subscription;
  constructor(public authService: AuthenticationService, private router: Router,
  public afAuth: AngularFireAuth,
  private platform: Platform) {

  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.subscription = this.afAuth.authState.subscribe(auth => {
        if (!auth) {
          console.log('non connecté');
          this.router.navigateByUrl('/login');
        } else {
          (this.router.url === '/') && this.router.navigateByUrl('/tabs/today');
          console.log('Connecté: ' + auth.uid);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeApp();
  }


}
